<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">经办人</span>
              <a-button block style="text-align: left">
                {{ user.userName }}
              </a-button>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">经办部门</span>
              <a-button block style="text-align: left">
                {{ department.deptUniqueName }}
              </a-button>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              label="备注"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="[
                  'remarks',
                  { initialValue: remarks,rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="between">
          <div class="title">入库单</div>
          <AddMaterial @add="add" :canEdit="!ifDetail" :ifStorage="true"/>
        </div>

        <a-table bordered :dataSource="list" :pagination="false">
          <a-table-column title="酒水品名" data-index="name"> </a-table-column>

          <a-table-column title="类型">
            <template slot-scope="text">
              <div class="left">
                <DataDictFinder dictType="invType" :dictValue="text.typeInv" />
                <span>-</span>
                {{ renderSubType(text.typeInv, text.typeSub) }}
              </div>
            </template>
          </a-table-column>

          <a-table-column title="单位" data-index="unit" align="center">
          </a-table-column>
          <a-table-column title="单价" align="center">
            <template slot-scope="text">
              <span style="color: #1890ff">{{ text.unitPrice }}</span>
            </template>
          </a-table-column>

          <a-table-column title="数量" align="center">
            <template slot-scope="text">
              <span style="color: #1890ff">{{ text.needQuantity }}</span>
            </template>
          </a-table-column>

          <a-table-column title="操作" align="center" width="100px" v-if="!ifDetail">
            <template slot-scope="text, record, index">
              <a href="#" class="danger" @click.prevent="remove(index)">
                移除
              </a>
            </template>
          </a-table-column>
        </a-table>

        <div class="center" v-if="!ifDetail">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading="loading">
              {{ id ? "重新提交" : "保存" }}
            </a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import AddMaterial from "./components/add-material.vue";
import { addStorage, fetchWareDetail, reapply } from "@/api/oa/material";
export default {
  components: {
    AddMaterial,
  },

  data() {
    return {
      form: this.$form.createForm(this),

      list: [],

      loading: false,

      id: null, // 已发起申领申请的 id
      remarks: '',
      ifDetail: false
    };
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id) {
      this.ifDetail = true
      fetchWareDetail(id).then(res=>{
        this.remarks = res.remarks
        this.list = res.subList
      })
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["department"]),

    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("invType");
    },
  },

  methods: {
    renderSubType(type, subType) {
      const obj = this.typeList.find((item) => item.value === type);
      if (obj && Array.isArray(obj.children)) {
        const subTypeObj = obj.children.find((item) => item.value === subType);
        if (subTypeObj) {
          return subTypeObj.name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    // 用户选择了品名和数量
    add(payload) {
      this.list.push(payload);
    },

    remove(index) {
      this.list.splice(index, 1);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.list.length === 0) {
            this.$message.error("请添加申领物料");
            return;
          }

          this.loading = true;
          const { remarks } = values;
          let totalPrice = 0;
          this.list.forEach(v=>{
            totalPrice += v.needQuantity * v.unitPrice
          })
          addStorage({
            // 申领人
            applicantId: this.user.uuid,
            applicantName: this.user.userName,
            attachments: '',
            totalPrice: totalPrice,
            // 申领部门
            deptId: this.department.deptId,
            deptName: this.department.deptUniqueName,

            remarks,

            subList: this.list.map((item) => {
              return {
                name: item.name,
                materialId: item.id,
                quantity: item.needQuantity,
                typeInv: item.typeInv,
                typeSub: item.typeSub,
                unit: item.unit,
                unitPrice: item.unitPrice,
              };
            }),
          })
            .then(() => {
              this.list = [];
              this.form.resetFields();
            })
            .finally(() => {
              this.loading = false;
            });

        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.title {
  margin-top: 30px;
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  color: #1890ff;
}

.center {
  margin-top: 80px;
}
</style>
